@import "../node_modules/bootstrap/scss/bootstrap";

.card {
    border: 1px solid #F6F6F6;
}

.no-style {
    list-style-type: none;
}

.w-700{
    max-width: 700px;
}

.text-justify {
    text-align: justify;
}

.text-justify > h1,h2,h3,h4 {
    text-align: start;
}